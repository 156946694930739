import React from 'react'
import PropTypes from 'prop-types'
import styles from './reasons_card.module.css'

const ReasonsCard = ({ title, imageSrc, text }) => {
  return (
    <div className={styles.root}>
      <div className={styles.imageContainer}>
        <img loading='lazy' alt='' src={imageSrc} aria-hidden={true} className={styles.image} />
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  )
}

ReasonsCard.propTypes = {
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default ReasonsCard
