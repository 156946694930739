import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import styles from './why_work_here_section.module.css'
import CenteredSectionWrapper from '../centered_section_wrapper/centered_section_wrapper'
import Figma from '../figma/figma'
import ReasonsCard from '../reasons_card/reasons_card'
import { useTranslation } from '../../i18n'
import translations from './why_work_here_section.translations'
import { frontdoorAnchors } from '../../routing/paths'

const WhyWorkHereSection = () => {
  const t = useTranslation(translations)

  return (
    <section className={styles.root}>
      <CenteredSectionWrapper>
        <div className={styles.borderContainer}>
          <h2>
            <Figma.Text type='heading' block>
              {t.heading}
            </Figma.Text>
          </h2>
          <h3>
            <Figma.Text type='headingGrey' block>
              {t.subheading}
            </Figma.Text>
          </h3>
          <div className={styles.cta}>
            <AnchorWithArrow href={frontdoorAnchors.careersPositions} arrowDirection='down'>
              {t.cta}
            </AnchorWithArrow>
          </div>

          <div className={styles.cards}>
            {t.benefits.map((card, i) => (
              <ReasonsCard title={card.title} imageSrc={card.imageSrc} text={card.text} key={i} />
            ))}
          </div>
        </div>
      </CenteredSectionWrapper>
    </section>
  )
}

export default WhyWorkHereSection
