import purposeAndProfit from './purpose_and_profit.svg'
import globalImpact from './global_impact.svg'
import diverse from './diverse.svg'

export default {
  en: {
    heading: 'Why work here?',
    subheading: 'Here are a few reasons',
    cta: 'See Open Roles',
    benefits: [
      {
        title: 'Purpose and Profit',
        imageSrc: purposeAndProfit,
        text: 'Envato is not just about the bottom line. We’re a values-driven business that strives to have a positive impact on the lives of all of our stakeholders, from content creators and customers to staff and the wider global creative community. It’s work, made with heart.'
      },
      {
        title: 'Global Impact',
        imageSrc: globalImpact,
        text: 'We work tirelessly to support an international community of creatives. In doing so, we help more than two million customers every year deliver high-quality and on-trend creative projects, while ensuring our content authors get paid every time that happens.'
      },
      {
        title: 'Diverse and Inclusive',
        imageSrc: diverse,
        text: 'We’re committed to ensuring all our staff feel welcome, included and respected every day they work for Envato, by providing a caring, engaged and flexible work environment.'
      }
    ]
  },
  es: {
    heading: '¿Por qué trabajar aquí?',
    subheading: 'He aquí algunas razones',
    cta: 'Ve Nuestras Vacantes',
    benefits: [
      {
        title: 'Propósito y Ganancias',
        imageSrc: purposeAndProfit,
        text: 'Envato no se trata solo del resultado final. Somos una empresa impulsada por valores que se esfuerza por tener un impacto positivo en la vida de todas nuestras partes interesadas, desde los creadores de contenido y los clientes hasta el personal y la comunidad creativa global en general.'
      },
      {
        title: 'Impacto Global',
        imageSrc: globalImpact,
        text: 'Trabajamos incansablemente para apoyar a una comunidad internacional de creativos. Al hacerlo, ayudamos a más de dos millones de clientes cada año a entregar proyectos creativos de alta calidad y en tendencia, y nos aseguramos de que nuestros autores de contenido reciban un pago cada vez que eso sucede. Es un trabajo hecho con el corazón.'
      },
      {
        title: 'Diverso e Incluyente',
        imageSrc: diverse,
        text: 'Estar clasificado entre los diez primeros lugares de la encuesta anual Great Place to Work de Australia no es una casualidad. Estamos comprometidos en asegurar que todo nuestro personal se sienta bienvenido, incluido y respetado todos los días que trabajan para Envato, al brindar un ambiente de trabajo afectuoso, comprometido y flexible.'
      }
    ]
  }
}
